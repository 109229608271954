import React from "react";
import "./App.scss";
import Focs from "./components/Focs";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Router>
    <Switch>
      <div className="App">
        <Route path="/*" component={Focs} />
        <Redirect from="*" to="/" />
      </div>
    </Switch>
    </Router>
  );
}

export default App;
