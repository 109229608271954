export const timezones = [
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Baker Island",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Howland Island",
  },

  {
    timezone: "Pacific/Pago_Pago",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "American Samoa",
  },
  {
    timezone: "Pacific/Pago_Pago",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Jarvis Island",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Kingman Reef",
  },
  {
    timezone: "Pacific/Johnston",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Palmyra Atoll",
  },
  {
    timezone: "Pacific/Niue",
    countryCode: "NZ",
    color1: "#01247D",
    color2: "#CC142B",
    name: " Niue",
  },

  {
    timezone: "Pacific/Rarotonga",
    countryCode: "NZ",
    color1: "#01247D",
    color2: "#CC142B",
    name: "Cook Islands",
  },
  {
    timezone: "Pacific/Honolulu",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Hawaii",
  },

  {
    timezone: "Pacific/Marquesas",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Marquesas Islands",
  },

  {
    timezone: "Pacific/Gambier",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Gambier Islands",
  },
  {
    timezone: "America/Juneau",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Alaska",
  },

  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Clipperton Island",
  },
  {
    timezone: "America/Tijuana",
    countryCode: "MX",
    color1: "#006847",
    color2: "#CE1125",
    name: "Baja California",
  },
  {
    timezone: "Pacific/Pitcairn",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Pitcairn Islands",
  },
  {
    timezone: "",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "California",
  },
  {
    timezone: "America/Los_Angeles",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Washington",
  },

  {
    timezone: "America/Edmonton",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Alberta",
  },
  {
    timezone: "America/Cambridge_Bay",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Northwest Territories",
  },
  {
    timezone: "na",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Lloydminster",
  },
  {
    timezone: "America/Whitehorse",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Yukon",
  },
  {
    timezone: "America/Phoenix",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Arizona",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Colorado",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Montana",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "New Mexico",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Utah",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Wyoming",
  },

  {
    timezone: "na",
    countryCode: "BZ",
    color1: "#003F86",
    color2: "#CE1125",
    name: "Belize",
  },
  {
    timezone: "America/Winnipeg",
    countryCode: "CN",
    color1: "#DD290E",
    color2: "#FFDE00",
    name: "Manitoba",
  },
  {
    timezone: "Pacific/Easter",
    countryCode: "CL",
    color1: "#D52B1E",
    color2: "#0138A6",
    name: "Easter Island",
  },
  {
    timezone: "America/Costa_Rica",
    countryCode: "CR",
    color1: "#1300B4",
    color2: "#D90000",
    name: "Costa Rica",
  },
  {
    timezone: "Pacific/Galapagos",
    countryCode: "EC",
    color1: "#FFE800",
    color2: "#DA000F",
    name: "Galápagos Islands",
  },
  {
    timezone: "America/El_Salvador",
    countryCode: "SV",
    color1: "#0E47AF",
    color2: "#FFFFFF",
    name: "El Salvador",
  },
  {
    timezone: "America/Guatemala",
    countryCode: "GT",
    color1: "#4997D0",
    color2: "#FFFFFF",
    name: "Guatemala",
  },
  {
    timezone: "na",
    countryCode: "HN",
    color1: "#0073CF",
    color2: "#FFFFFF",
    name: "Honduras",
  },
  {
    timezone: "America/Managua",
    countryCode: "NI",
    color1: "#0067C6",
    color2: "#FFFFFF",
    name: "Nicaragua",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Arkansas",
  },
  {
    timezone: "America/Chicago",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Illinois",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Iowa",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Louisiana",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Minnesota",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Mississippi",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Missouri",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Wisconsin",
  },

  {
    timezone: "America/Nassau",
    countryCode: "BS",
    color1: "#06CED6",
    color2: "#FFE900",
    name: "Bahamas",
  },
  {
    timezone: "America/Rio_Branco",
    countryCode: "BR",
    color1: "#219E45",
    color2: "#F8E509",
    name: "Acre",
  },
  {
    timezone: "na",
    countryCode: "CO",
    color1: "#FFE800",
    color2: "#08138D",
    name: "Columbia",
  },
  {
    timezone: "America/Havana",
    countryCode: "CU",
    color1: "#0050F0",
    color2: "#ED0000",
    name: "Cuba",
  },
  {
    timezone: "na",
    countryCode: "HT",
    color1: "#061F9F",
    color2: "#D20E33",
    name: "Haiti",
  },
  {
    timezone: "America/Jamaica",
    countryCode: "JM",
    color1: "#029900",
    color2: "#FECC00",
    name: "Jamacia",
  },
  {
    timezone: "na",
    countryCode: "MX",
    color1: "#006847",
    color2: "#CE1125",
    name: "Quintana Roo",
  },
  {
    timezone: "America/Panama",
    countryCode: "PA",
    color1: "#1100AB",
    color2: "#DB0000",
    name: "Panama",
  },
  {
    timezone: "America/Lima",
    countryCode: "PE",
    color1: "#D91122",
    color2: "#FFFFFF",
    name: "Peru",
  },
  {
    timezone: "na",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Cayman Islands",
  },
  {
    timezone: "America/Grand_Turk",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Turks and Caicos Islands",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Delaware",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "District of Columbia",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Georgia",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Maryland",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "New England",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "New Jersey",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "New York",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "North Carolina",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Ohio",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Pennsylvania",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "South Carolina",
  },
  {
    timezone: "na",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Virginia",
  },
  {
    timezone: "America/New_York",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "West Virginia",
  },
  {
    timezone: "Pacific/Midway",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Navassa Island",
  },

  {
    timezone: "America/Antigua",
    countryCode: "AG",
    color1: "#E20000",
    color2: "#0061FF",
    name: "Antigua and Barbuda",
  },
  {
    timezone: "na",
    countryCode: "BB",
    color1: "#00267F",
    color2: "#FFC625",
    name: "Barbados",
  },
  {
    timezone: "America/La_Paz",
    countryCode: "BO",
    color1: "#D52B1E",
    color2: "#FFE002",
    name: "Bolivia",
  },

  {
    timezone: "na",
    countryCode: "DK",
    color1: "#C80E2E",
    color2: "#FFFFFF",
    name: "Pituffik",
  },
  {
    timezone: "na",
    countryCode: "DM",
    color1: "#0E8C00",
    color2: "#E72810",
    name: "Dominica",
  },
  {
    timezone: "America/Santo_Domingo",
    countryCode: "DO",
    color1: "#00319C",
    color2: "#D62917",
    name: "Dominican Republic",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Guadeloupe",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Martinique",
  },
  {
    timezone: "America/St_Barthelemy",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Saint Barthélemy",
  },
  {
    timezone: "America/Marigot",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Saint-Martin",
  },
  {
    timezone: "na",
    countryCode: "GD",
    color1: "#FCD114",
    color2: "#017A5E",
    name: "Grenada",
  },
  {
    timezone: "na",
    countryCode: "GY",
    color1: "#389405",
    color2: "#FFDE06",
    name: "Guyana",
  },
  {
    timezone: "America/Aruba",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Aruba",
  },
  {
    timezone: "na",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Bonaire",
  },
  {
    timezone: "na",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Saba",
  },
  {
    timezone: "na",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Sint Eustatius",
  },
  {
    timezone: "America/Curacao",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Curaçao",
  },
  {
    timezone: "na",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Sint Maarten",
  },
  {
    timezone: "na",
    countryCode: "PY",
    color1: "#0038A8",
    color2: "#D52B1E",
    name: "Paraguay",
  },
  {
    timezone: "na",
    countryCode: "KN",
    color1: "#34A000",
    color2: "#C70000",
    name: "Saint Kitts and Nevis",
  },
  {
    timezone: "na",
    countryCode: "LC",
    color1: "#65CFFE",
    color2: "#FFCE02",
    name: "Saint Lucia",
  },
  {
    timezone: "na",
    countryCode: "VC",
    color1: "#F4F101",
    color2: "#1A9901",
    name: "Saint Vincent and the Grenadines",
  },
  {
    timezone: "utc/gmt+4",
    countryCode: "TT",
    color1: "#E00000",
    color2: "#FFFFFF",
    name: "Trinidad and Tobago",
  },
  {
    timezone: "utc/gmt+4",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Anguilla",
  },
  {
    timezone: "Atlantic/Bermuda",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Bermuda",
  },
  {
    timezone: "America/Tortola",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "British Virgin Islands",
  },
  {
    timezone: "utc/gmt+4",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Montserrat",
  },
  {
    timezone: "America/Puerto_Rico",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Puerto Rico",
  },
  {
    timezone: "America/St_Thomas",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "U.S. Virgin Islands",
  },
  {
    timezone: "utc/gmt+4",
    countryCode: "VE",
    color1: "#FECC00",
    color2: "#CF132A",
    name: "Venezuela",
  },

  {
    timezone: "America/St_Johns",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Newfoundland",
  },

  {
    timezone: "America/Moncton",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "New Brunswick",
  },

  {
    timezone: "utc/gmt+3",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Prince Edward Island",
  },
  {
    timezone: "utc/gmt+3",
    countryCode: "CA",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Nova Scotia",
  },
  {
    timezone: "utc/gmt+3",
    countryCode: "AR",
    color1: "#74ACDF",
    color2: "#FFFFFF",
    name: "Argentina",
  },
  {
    timezone: "na",
    countryCode: "CL",
    color1: "#D52B1E",
    color2: "#0138A6",
    name: "Magallanes and Chilean Antarctica Region",
  },
  {
    timezone: "utc/gmt+3",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "French Guiana",
  },
  {
    timezone: "utc/gmt+2",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Saint Pierre and Miquelon",
  },
  {
    timezone: "America/Paramaribo",
    countryCode: "SR",
    color1: "#367E3F",
    color2: "#B40B2D",
    name: "Suriname",
  },
  {
    timezone: "",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Falkland Islands",
  },
  {
    timezone: "Atlantic/Stanley",
    countryCode: "UY",
    color1: "#0038A8",
    color2: "#FFFFFF",
    name: "Uruguay",
  },

  {
    timezone: "utc/gmt+2",
    countryCode: "BR",
    color1: "#219E45",
    color2: "#F8E509",
    name: "Fernando de Noronha",
  },
  {
    timezone: "na",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "South Georgia and the South Sandwich Islands",
  },

  {
    timezone: "Atlantic/Cape_Verde",
    countryCode: "CV",
    color1: "#091773",
    color2: "#DE3928",
    name: "Cape Verde",
  },
  {
    timezone: "na",
    countryCode: "DK",
    color1: "#C80E2E",
    color2: "#FFFFFF",
    name: "Ittoqqortoormiit",
  },
  {
    timezone: "Atlantic/Azores",
    countryCode: "PT",
    color1: "#FE0100",
    color2: "#006600",
    name: "Azores islands",
  },

  {
    timezone: "America/Danmarkshavn",
    countryCode: "DK",
    color1: "#C80E2E",
    color2: "#FFFFFF",
    name: "Danmarkshavn",
  },
  {
    timezone: "Atlantic/Faroe",
    countryCode: "DK",
    color1: "#C80E2E",
    color2: "#FFFFFF",
    name: "Faroe Islands",
  },
  {
    timezone: "Africa/Ouagadougou",
    countryCode: "BF",
    color1: "#DE0000",
    color2: "#34A000",
    name: "Burkina Faso",
  },
  {
    timezone: "Africa/Abidjan",
    countryCode: "CI",
    color1: "#FF9900",
    color2: "#00CD01",
    name: "Côte d'Ivoire",
  },
  {
    timezone: "utc/gmt0",
    countryCode: "GM",
    color1: "#FE0100",
    color2: "#029900",
    name: "Gambia",
  },
  {
    timezone: "Africa/Accra",
    countryCode: "GH",
    color1: "#CE1125",
    color2: "#006B3F",
    name: "Ghana",
  },
  {
    timezone: "Africa/Conakry",
    countryCode: "GN",
    color1: "#FE0100",
    color2: "#029900",
    name: "Guinea",
  },
  {
    timezone: "utc/gmt0",
    countryCode: "GW",
    color1: "#FCD114",
    color2: "#009E49",
    name: "Guinea-Bissau",
  },
  {
    timezone: "Atlantic/Reykjavik",
    countryCode: "IS",
    color1: "#003897",
    color2: "#D72728",
    name: "Iceland",
  },
  {
    timezone: "Europe/Dublin",
    countryCode: "IE",
    color1: "#009A49",
    color2: "#FF7800",
    name: "Ireland",
  },
  {
    timezone: "America/Costa_Rica",
    countryCode: "LR",
    color1: "#CC0000",
    color2: "#060066",
    name: "Liberia",
  },
  {
    timezone: "utc/gmt0",
    countryCode: "ML",
    color1: "#009A00",
    color2: "#FE0100",
    name: "Mali",
  },
  {
    timezone: "Africa/Nouakchott",
    countryCode: "MR",
    color1: "#009A00",
    color2: "#CD293E",
    name: "Mauritania",
  },
  {
    timezone: "Africa/Sao_Tome",
    countryCode: "ST",
    color1: "#12AD2B",
    color2: "#FFCE02",
    name: "São Tomé and Príncipe",
  },
  {
    timezone: "Atlantic/Canary",
    countryCode: "ES",
    color1: "#FEC400",
    color2: "#C60B1E",
    name: "Canary Islands",
  },
  {
    timezone: "utc/gmt0",
    countryCode: "SN",
    color1: "#0C7125",
    color2: "#FDFF02",
    name: "Senegal",
  },
  {
    timezone: "Africa/Freetown",
    countryCode: "SL",
    color1: "#00CD01",
    color2: "#1700CD",
    name: "Sierra Leone",
  },
  {
    timezone: "Africa/Lome",
    countryCode: "TG",
    color1: "#128600",
    color2: "#FFE302",
    name: "Togo",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "GG",
    color1: "#E8102D",
    color2: "#F9DD15",
    name: "Guernsey",
  },
  {
    timezone: "Europe/Isle_of_Man",
    countryCode: "IM",
    color1: "#BA0000",
    color2: "#FFFFFF",
    name: "Isle of Man",
  },
  {
    timezone: "na",
    countryCode: "JE",
    color1: "#FFFFFF",
    color2: "#DF0F2C",
    name: "Jersey",
  },
  {
    timezone: "Atlantic/St_Helena",
    countryCode: "SH",
    color1: "#060066",
    color2: "#CE1125",
    name: "Saint Helena",
  },
  {
    timezone: "Europe/London",
    countryCode: "GB-ENG",
    color1: "#FFFFFF",
    color2: "#CE1124",
    name: "England",
  },
  {
    timezone: "Europe/London",
    countryCode: "GB-SCT",
    color1: "#0065BD",
    color2: "#FFFFFF",
    name: "Scotland",
  },
  {
    timezone: "Europe/London",
    countryCode: "GB-WLS",
    color1: "#00AB38",
    color2: "#FFFFFF",
    name: "Wales",
  },
  {
    timezone: "Europe/London",
    countryCode: "GB-NIR",
    color1: "#FFFFFF",
    color2: "#CC0000",
    name: "Northern Ierland",
  },

  {
    timezone: "Europe/Tirane",
    countryCode: "AL",
    color1: "#E41E1F",
    color2: "#000000",
    name: "Albania",
  },
  {
    timezone: "Africa/Algiers",
    countryCode: "DZ",
    color1: "#006233",
    color2: "#FFFFFF",
    name: "Algeria",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "AD",
    color1: "#0B18A7",
    color2: "#D00F3A",
    name: "Andorra",
  },
  {
    timezone: "na",
    countryCode: "AO",
    color1: "#FE0100",
    color2: "#000000",
    name: "Angola",
  },
  {
    timezone: "Europe/Vienna",
    countryCode: "AT",
    color1: "#ED2939",
    color2: "#FFFFFF",
    name: "Austria",
  },
  {
    timezone: "Europe/Brussels",
    countryCode: "BE",
    color1: "#FFD809",
    color2: "#F21730",
    name: "Belgium",
  },
  {
    timezone: "Africa/Lagos",
    countryCode: "BJ",
    color1: "#309400",
    color2: "#FFD600",
    name: "Benin",
  },
  {
    timezone: "Europe/Sarajevo",
    countryCode: "BA",
    color1: "#0E0099",
    color2: "#FECC00",
    name: "Bosnia and Herzegovina",
  },
  {
    timezone: "Africa/Douala",
    countryCode: "CM",
    color1: "#FCD114",
    color2: "#017A5E",
    name: "Cameroon",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "CF",
    color1: "#FE0100",
    color2: "#FDFF02",
    name: "Central African Republic",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "TD",
    color1: "#FDFF02",
    color2: "#FE0100",
    name: "Chad",
  },
  {
    timezone: "na",
    countryCode: "CG",
    color1: "#04CA01",
    color2: "#FDFF02",
    name: "Republic of the Congo",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Bandundu",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Bas-Congo",
  },
  {
    timezone: "America/Guayaquil",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Équateur",
  },
  {
    timezone: "Africa/ Kinshasa",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Kinshasa",
  },
  {
    timezone: "Europe/Zagreb",
    countryCode: "HR",
    color1: "#FE0100",
    color2: "#161796",
    name: "Croatia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CZ",
    color1: "#07006E",
    color2: "#E80000",
    name: "Czech Republic",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "DK",
    color1: "#C80E2E",
    color2: "#FFFFFF",
    name: "Denmark",
  },
  {
    timezone: "Africa/Malabo",
    countryCode: "GQ",
    color1: "#3D9B00",
    color2: "#E32118",
    name: "Equatorial Guinea",
  },
  {
    timezone: "Europe/Paris",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "France",
  },
  {
    timezone: "Africa/Libreville",
    countryCode: "GA",
    color1: "#016DBB",
    color2: "#36A000",
    name: "Gabon",
  },
  {
    timezone: "na",
    countryCode: "DE",
    color1: "#DD0000",
    color2: "#FFCE02",
    name: "Germany",
  },
  {
    timezone: "Europe/Budapest",
    countryCode: "HU",
    color1: "#D43515",
    color2: "#378D00",
    name: "Hungary",
  },
  {
    timezone: "Europe/Rome",
    countryCode: "IT",
    color1: "#009245",
    color2: "#CE2A36",
    name: "Italy",
  },
  {
    timezone: "Europe/Belgrade",
    countryCode: "XK",
    color1: "#244AA4",
    color2: "#CFA650",
    name: "Kosovo",
  },
  {
    timezone: "Europe/Vaduz",
    countryCode: "LI",
    color1: "#002A7F",
    color2: "#CE1125",
    name: "Liechtenstein",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "LU",
    color1: "#00A1DE",
    color2: "#ED2939",
    name: "Luxembourg",
  },
  {
    timezone: "Europe/Malta",
    countryCode: "MT",
    color1: "#CE0000",
    color2: "#FFFFFF",
    name: "Malta",
  },
  {
    timezone: "Europe/Monaco",
    countryCode: "MC",
    color1: "#F21730",
    color2: "#FFFFFF",
    name: "Monaco",
  },
  {
    timezone: "Europe/Podgorica",
    countryCode: "ME",
    color1: "#C40006",
    color2: "#D4AF3B",
    name: "Montenegro",
  },
  {
    timezone: "Africa/Casablanca",
    countryCode: "MA",
    color1: "#C1262C",
    color2: "#006233",
    name: "Morocco",
  },
  {
    timezone: "Europe/Amsterdam",
    countryCode: "NL",
    color1: "#21468B",
    color2: "#AE1B27",
    name: "Netherlands",
  },
  {
    timezone: "Africa/Niamey",
    countryCode: "NE",
    color1: "#E05204",
    color2: "#08B02B",
    name: "Niger",
  },
  {
    timezone: "Africa/Lagos",
    countryCode: "NG",
    color1: "#008753",
    color2: "#FFFFFF",
    name: "Nigeria",
  },
  {
    timezone: "Europe/Skopje",
    countryCode: "MK",
    color1: "#FFE600",
    color2: "#D20000",
    name: "North Macedonia",
  },
  {
    timezone: "Europe/Oslo",
    countryCode: "NO",
    color1: "#ED2939",
    color2: "#012663",
    name: "Norway",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "PL",
    color1: "#DC133C",
    color2: "#FFFFFF",
    name: "Poland",
  },
  {
    timezone: "Europe/San_Marino",
    countryCode: "SM",
    color1: "#18B6EF",
    color2: "#FFFFFF",
    name: "San Marino",
  },
  {
    timezone: "Europe/Belgrade",
    countryCode: "RS",
    color1: "#0C4075",
    color2: "#C6363C",
    name: "Serbia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "SK",
    color1: "#EE1C25",
    color2: "#0A4EA2",
    name: "Slovakia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "SI",
    color1: "#1400BF",
    color2: "#D50000",
    name: "Slovenia",
  },
  {
    timezone: "Europe/Madrid",
    countryCode: "ES",
    color1: "#FEC400",
    color2: "#C60B1E",
    name: "Balearic Islands",
  },
  {
    timezone: "Africa/Ceuta",
    countryCode: "ES",
    color1: "#FEC400",
    color2: "#C60B1E",
    name: "Ceuta",
  },
  {
    timezone: "Africa/Ceuta",
    countryCode: "ES",
    color1: "#FEC400",
    color2: "#C60B1E",
    name: "Melilla",
  },
  {
    timezone: "Europe/Stockholm",
    countryCode: "SE",
    color1: "#0069A6",
    color2: "#FECC00",
    name: "Sweden",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CH",
    color1: "#D52B1E",
    color2: "#FFFFFF",
    name: "Switzerland",
  },
  {
    timezone: "utc/gmt-1",
    countryCode: "TN",
    color1: "#E70012",
    color2: "#FFFFFF",
    name: "Tunisia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Gibraltar",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "VA",
    color1: "#FFE001",
    color2: "#FFFFFF",
    name: "Vatican City",
  },

  {
    timezone: "Africa/Gaborone",
    countryCode: "BW",
    color1: "#00CBFF",
    color2: "#000000",
    name: "Botswana",
  },
  {
    timezone: "Europe/Sofia",
    countryCode: "BG",
    color1: "#00966E",
    color2: "#D62611",
    name: "Bulgaria",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "BI",
    color1: "#FE0100",
    color2: "#00CF00",
    name: "Burundi",
  },
  {
    timezone: "Asia/Nicosia",
    countryCode: "CY",
    color1: "#FFFFFF",
    color2: "#D47600",
    name: "Cyprus",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Kasaï Occidental",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Kasaï Oriental",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Katanga",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Maniema",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Nord-Kivu",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Orientale",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "CD",
    color1: "#017FFF",
    color2: "#CE0F21",
    name: "Sud-Kivu",
  },
  {
    timezone: "Africa/Cairo",
    countryCode: "EG",
    color1: "#CE1125",
    color2: "#000000",
    name: "Egypt",
  },
  {
    timezone: "Europe/Tallinn",
    countryCode: "EE",
    color1: "#1291FF",
    color2: "#FFFFFF",
    name: "Estonia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "SZ",
    color1: "#B10A0B",
    color2: "#3D5EB9",
    name: "Eswatini",
  },
  {
    timezone: "Europe/Helsinki",
    countryCode: "FI",
    color1: "#FFFFFF",
    color2: "#003480",
    name: "Finland",
  },
  {
    timezone: "Europe/Athens",
    countryCode: "GR",
    color1: "#005BAE",
    color2: "#FFFFFF",
    name: "Greece",
  },
  {
    timezone: "Asia/Jerusalem",
    countryCode: "IL",
    color1: "#FFFFFF",
    color2: "#1700CC",
    name: "Israel",
  },
  {
    timezone: "Asia/Amman",
    countryCode: "JO",
    color1: "#FE0100",
    color2: "#029900",
    name: "Jordan",
  },
  {
    timezone: "Europe/Riga",
    countryCode: "LV",
    color1: "#971D32",
    color2: "#FFFFFF",
    name: "Latvia",
  },
  {
    timezone: "Asia/Beirut",
    countryCode: "LB",
    color1: "#FE0100",
    color2: "#FFFFFF",
    name: "Lebanon",
  },
  {
    timezone: "Africa/Maseru",
    countryCode: "LS",
    color1: "#061F9F",
    color2: "#009543",
    name: "Lesotho",
  },
  {
    timezone: "Europe/Vilnius",
    countryCode: "LT",
    color1: "#FDB911",
    color2: "#C1262C",
    name: "Lithuania",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "LY",
    color1: "#000000",
    color2: "#219E46",
    name: "Libya",
  },
  {
    timezone: "Africa/Blantyre",
    countryCode: "MW",
    color1: "#F41208",
    color2: "#21873B",
    name: "Malawi",
  },
  {
    timezone: "na",
    countryCode: "MD",
    color1: "#00319C",
    color2: "#DE2010",
    name: "Transnistria",
  },
  {
    timezone: "Africa/Maputo",
    countryCode: "MZ",
    color1: "#FE0100",
    color2: "#FFCA00",
    name: "Mozambique",
  },
  {
    timezone: "Africa/Windhoek",
    countryCode: "NA",
    color1: "#38A000",
    color2: "#3562A2",
    name: "Namibia",
  },
  {
    timezone: "Asia/Gaza",
    countryCode: "PS",
    color1: "#FE0100",
    color2: "#029900",
    name: "State of Palestine",
  },
  {
    timezone: "Europe/Bucharest",
    countryCode: "RO",
    color1: "#00319C",
    color2: "#DE2010",
    name: "Romania",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Kaliningrad Oblast",
  },
  {
    timezone: "Africa/Johannesburg",
    countryCode: "ZA",
    color1: "#019932",
    color2: "#FECC00",
    name: "South Africa",
  },
  {
    timezone: "Africa/Kigali",
    countryCode: "RW",
    color1: "#00A1DE",
    color2: "#FAD200",
    name: "Rwanda",
  },
  {
    timezone: "Africa/Khartoum",
    countryCode: "SD",
    color1: "#009A00",
    color2: "#FE0100",
    name: "Sudan",
  },
  {
    timezone: "Asia/Damascus",
    countryCode: "SY",
    color1: "#FE0100",
    color2: "#FFFFFF",
    name: "Syria",
  },
  {
    timezone: "na",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "Akrotiri and Dhekelia",
  },
  {
    timezone: "Africa/Lusaka",
    countryCode: "ZM",
    color1: "#198A00",
    color2: "#EF7D00",
    name: "Zambia",
  },
  {
    timezone: "utc/gmt-2",
    countryCode: "ZW",
    color1: "#006400",
    color2: "#FFD201",
    name: "Zimbabwe",
  },

  {
    timezone: "Asia/Bahrain",
    countryCode: "BH",
    color1: "#E1000F",
    color2: "#FFFFFF",
    name: "Bahrain",
  },
  {
    timezone: "Europe/Minsk",
    countryCode: "BY",
    color1: "#B20000",
    color2: "#419F00",
    name: "Belarus",
  },
  {
    timezone: "Indian/Comoro",
    countryCode: "KM",
    color1: "#219E47",
    color2: "#3B5AA3",
    name: "Comoros",
  },
  {
    timezone: "Africa/Djibouti",
    countryCode: "DJ",
    color1: "#6699FF",
    color2: "#04CC00",
    name: "Djibouti",
  },
  {
    timezone: "Africa/Asmara",
    countryCode: "ER",
    color1: "#219E47",
    color2: "#B3D7F4",
    name: "Eritrea",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "ET",
    color1: "#2A8C08",
    color2: "#EF2018",
    name: "Ethiopia",
  },
  {
    timezone: "na",
    countryCode: "GE",
    color1: "#FFFFFF",
    color2: "#FE0100",
    name: "Abkhazia",
  },
  {
    timezone: "na",
    countryCode: "GE",
    color1: "#FFFFFF",
    color2: "#FE0100",
    name: "South Ossetia",
  },
  {
    timezone: "Asia/Baghdad",
    countryCode: "IQ",
    color1: "#CE1125",
    color2: "#FFFFFF",
    name: "Iraq",
  },
  {
    timezone: "Africa/Nairobi",
    countryCode: "KE",
    color1: "#BB0000",
    color2: "#006600",
    name: "Kenya",
  },
  {
    timezone: "Asia/Kuwait",
    countryCode: "KW",
    color1: "#02D841",
    color2: "#F31631",
    name: "Kuwait",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "MG",
    color1: "#FF3318",
    color2: "#05CC27",
    name: "Madagascar",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "QA",
    color1: "#8D1A3D",
    color2: "#FFFFFF",
    name: "Qatar",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Central Federal District",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "North Caucasian Federal District",
  },
  {
    timezone: "Asia/Riyadh",
    countryCode: "SA",
    color1: "#199D02",
    color2: "#FFFFFF",
    name: "Saudi Arabia",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "SO",
    color1: "#40A6FF",
    color2: "#FFFFFF",
    name: "Somalia",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "ZA",
    color1: "#019932",
    color2: "#FECC00",
    name: "Prince Edward Islands",
  },
  {
    timezone: "Africa/Juba",
    countryCode: "SS",
    color1: "#0E47AF",
    color2: "#FCDD09",
    name: "South Sudan",
  },
  {
    timezone: "Africa/Dar_es_Salaam",
    countryCode: "TZ",
    color1: "#029900",
    color2: "#0399FF",
    name: "Tanzania",
  },
  {
    timezone: "Europe/Istanbul",
    countryCode: "TR",
    color1: "#E30A16",
    color2: "#FFFFFF",
    name: "Turkey",
  },
  {
    timezone: "utc/gmt-3",
    countryCode: "UG",
    color1: "#FEE701",
    color2: "#DE3906",
    name: "Uganda",
  },
  {
    timezone: "Asia/Aden",
    countryCode: "YE",
    color1: "#F00600",
    color2: "#000000",
    name: "Yemen",
  },
  {
    timezone: "Europe/Simferopol",
    countryCode: "UA",
    color1: "#005ABB",
    color2: "#FFD501",
    name: "Crimea",
  },

  {
    timezone: "utc/gmt-3.5",
    countryCode: "IR",
    color1: "#DA0000",
    color2: "#239F3F",
    name: "Iran",
  },

  {
    timezone: "",
    countryCode: "AM",
    color1: "#FE0100",
    color2: "#FFA500",
    name: "Armenia",
  },
  {
    timezone: "Asia/Yerevan",
    countryCode: "AZ",
    color1: "#00B9E4",
    color2: "#3E9C35",
    name: "Azerbaijan",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Crozet Islands",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Glorioso Islands",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Tromelin Island",
  },
  {
    timezone: "Indian/Mauritius",
    countryCode: "MU",
    color1: "#FFCD00",
    color2: "#00A04C",
    name: "Mauritius",
  },
  {
    timezone: "Asia/Muscat",
    countryCode: "OM",
    color1: "#EF2C28",
    color2: "#009024",
    name: "Oman",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Astrakhan Oblast",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Volgograd Oblast",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Samara Oblast",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Saratov Oblast",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Udmurtia",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Ulyanovsk Oblast",
  },
  {
    timezone: "Indian/Mahe",
    countryCode: "SC",
    color1: "#0E0099",
    color2: "#FDFF02",
    name: "Seychelles",
  },
  {
    timezone: "utc/gmt-4",
    countryCode: "AE",
    color1: "#01722E",
    color2: "#FE0100",
    name: "United Arab Emirates",
  },

  {
    timezone: "Asia/Kabul",
    countryCode: "AF",
    color1: "#BF0000",
    color2: "#029900",
    name: "Afghanistan",
  },

  {
    timezone: "Indian/Kerguelen",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Heard Island and McDonald Islands",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Île Amsterdam",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Île Saint-Paul",
  },
  {
    timezone: "na",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Kerguelen Islands",
  },
  {
    timezone: "Asia/Aqtau",
    countryCode: "KZ",
    color1: "#6FDCFF",
    color2: "#FFE400",
    name: "Aktobe",
  },
  {
    timezone: "Asia/Oral",
    countryCode: "KZ",
    color1: "#6FDCFF",
    color2: "#FFE400",
    name: "Atyrau",
  },
  {
    timezone: "Asia/Qyzylorda",
    countryCode: "KZ",
    color1: "#6FDCFF",
    color2: "#FFE400",
    name: "Kyzylorda",
  },
  {
    timezone: "Asia/Qyzylorda",
    countryCode: "KZ",
    color1: "#6FDCFF",
    color2: "#FFE400",
    name: "Mangystau",
  },
  {
    timezone: "Asia/Oral",
    countryCode: "KZ",
    color1: "#6FDCFF",
    color2: "#FFE400",
    name: "West Kazakhstan",
  },
  {
    timezone: "utc/gmt-5",
    countryCode: "MV",
    color1: "#D20E33",
    color2: "#007E3A",
    name: "Maldives",
  },
  {
    timezone: "Asia/Karachi",
    countryCode: "PK",
    color1: "#0C590A",
    color2: "#FFFFFF",
    name: "Pakistan",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Ural Federal District",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Bashkortostan",
  },
  {
    timezone: "",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Orenburg Oblast",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Perm Krai",
  },
  {
    timezone: "Asia/Dushanbe",
    countryCode: "TJ",
    color1: "#CC0000",
    color2: "#006600",
    name: "Tajikistan",
  },
  {
    timezone: "Asia/Ashgabat",
    countryCode: "TM",
    color1: "#28AE66",
    color2: "#CA3644",
    name: "Turkmenistan",
  },
  {
    timezone: "Asia/Samarkand",
    countryCode: "UZ",
    color1: "#1DB539",
    color2: "#0199B5",
    name: "Uzbekistan",
  },

  {
    timezone: "Asia/Kolkata",
    countryCode: "IN",
    color1: "#FF9932",
    color2: "#128807",
    name: "India",
  },
  {
    timezone: "Asia/Colombo",
    countryCode: "LK",
    color1: "#8C2029",
    color2: "#FFB700",
    name: "Sri Lanka",
  },
  {
    timezone: "Asia/Kathmandu",
    countryCode: "NP",
    color1: "#FFFFFF",
    color2: "#CE0000",
    name: "Nepal",
  },

  {
    timezone: "Asia/Dhaka",
    countryCode: "BD",
    color1: "#006A4E",
    color2: "#F42A40",
    name: "Bangladesh",
  },
  {
    timezone: "utc/gmt-6",
    countryCode: "BT",
    color1: "#FFD51E",
    color2: "#FF4E12",
    name: "Bhutan",
  },
  {
    timezone: "Asia/Bishkek",
    countryCode: "KG",
    color1: "#BE0027",
    color2: "#FDFF02",
    name: "Kyrgyzstan",
  },
  {
    timezone: "utc/gmt-6",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Omsk Oblast",
  },
  {
    timezone: "Indian/Chagos",
    countryCode: "GB",
    color1: "#022169",
    color2: "#C80E2E",
    name: "British Indian Ocean Territory",
  },

  {
    timezone: "Indian/Cocos",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Cocos (Keeling) Islands",
  },
  {
    timezone: "Asia/Rangoon",
    countryCode: "MM",
    color1: "#FECB03",
    color2: "#35B233",
    name: "Myanmar",
  },

  {
    timezone: "Pacific/Kiritimati",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Christmas Island",
  },
  {
    timezone: "Asia/Phnom_Penh",
    countryCode: "KH",
    color1: "#E00024",
    color2: "#032EA1",
    name: "Cambodia",
  },
  {
    timezone: "utc/gmt-6",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Central Kalimantan",
  },
  {
    timezone: "utc/gmt-6",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Java",
  },
  {
    timezone: "utc/gmt-6",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Riau Islands",
  },
  {
    timezone: "utc/gmt-7",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Sumatra",
  },
  {
    timezone: "utc/gmt-7",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "West Kalimantan",
  },
  {
    timezone: "Asia/Vientiane",
    countryCode: "LA",
    color1: "#002868",
    color2: "#CE1125",
    name: "Laos",
  },
  {
    timezone: "Asia/Hovd",
    countryCode: "MN",
    color1: "#C4272F",
    color2: "#015097",
    name: "Hovd",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Altai Krai",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Altai Republic",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Kemerovo Oblast",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Khakassia",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Krasnoyarsk Krai",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Novosibirsk Oblast",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Tomsk Oblast",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Tuva",
  },
  {
    timezone: "Asia/Bangkok",
    countryCode: "TH",
    color1: "#2D294A",
    color2: "#A51931",
    name: "Thailand",
  },
  {
    timezone: "Asia/Ho_Chi_Minh",
    countryCode: "VN",
    color1: "#EC0014",
    color2: "#FDFF02",
    name: "Vietnam",
  },

  {
    timezone: "Australia/Perth",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Perth",
  },
  {
    timezone: "Asia/Brunei",
    countryCode: "BN",
    color1: "#F7E016",
    color2: "#CF1125",
    name: "Brunei",
  },
  {
    timezone: "Asia/Shanghai",
    countryCode: "CN",
    color1: "#DD290E",
    color2: "#FFDE00",
    name: "China",
  },
  {
    timezone: "Asia/Hong_Kong",
    countryCode: "HK",
    color1: "#BB0001",
    color2: "#FFFFFF",
    name: "Hong Kong",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "East Kalimantan",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Lesser Sunda Islands",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "North Kalimantan",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "South Kalimantan",
  },
  {
    timezone: "Asia/Makassar",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Sulawesi",
  },
  {
    timezone: "Asia/Macau",
    countryCode: "MO",
    color1: "#01785E",
    color2: "#FFFFFF",
    name: "Macau",
  },
  {
    timezone: "Asia/Kuala_Lumpur",
    countryCode: "MY",
    color1: "#060066",
    color2: "#CC0000",
    name: "Malaysia",
  },
  {
    timezone: "na",
    countryCode: "PH",
    color1: "#0038A8",
    color2: "#CE1125",
    name: "Philippines",
  },
  {
    timezone: "Asia/Ulaanbaatar",
    countryCode: "MN",
    color1: "#C4272F",
    color2: "#015097",
    name: "Ulaanbaatar",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Buryatia",
  },
  {
    timezone: "Asia/Irkutsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Irkutsk Oblast",
  },
  {
    timezone: "Asia/Singapore",
    countryCode: "SG",
    color1: "#DF0000",
    color2: "#FFFFFF",
    name: "Singapore",
  },
  {
    timezone: "utc/gmt-8",
    countryCode: "TW",
    color1: "#FF0000",
    color2: "#0D0095",
    name: "Taiwan",
  },

  {
    timezone: "na",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Eucla",
  },

  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Maluku Islands",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "Papua",
  },
  {
    timezone: "na",
    countryCode: "ID",
    color1: "#E7000F",
    color2: "#FFFFFF",
    name: "West Papua",
  },
  {
    timezone: "Asia/Tokyo",
    countryCode: "JP",
    color1: "#D30000",
    color2: "#FFFFFF",
    name: "Japan",
  },
  {
    timezone: "Asia/Pyongyang",
    countryCode: "KP",
    color1: "#C60000",
    color2: "#3D5698",
    name: "North Korea",
  },
  {
    timezone: "Pacific/Palau",
    countryCode: "PW",
    color1: "#4AADD6",
    color2: "#FFDE00",
    name: "Palau",
  },
  {
    timezone: "Asia/Yakutsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Amur Oblast",
  },
  {
    timezone: "Asia/Yakutsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Sakha Republic",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Zabaykalsky Krai",
  },
  {
    timezone: "utc/gmt-9",
    countryCode: "KR",
    color1: "#C70000",
    color2: "#3D5997",
    name: "South Korea",
  },
  {
    timezone: "na",
    countryCode: "TL",
    color1: "#CB000F",
    color2: "#F8C10B",
    name: "Timor-Leste",
  },

  {
    timezone: "Australia/Broken_Hill",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Broken Hill",
  },
  {
    timezone: "utc/gmt-9.5",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Northern Territory",
  },
  {
    timezone: "na",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "South Australia",
  },

  {
    timezone: "na",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Australian Capital Territory",
  },
  {
    timezone: "Australia/Brisbane",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Queensland",
  },
  {
    timezone: "Australia/Hobart",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Tasmania",
  },
  {
    timezone: "	Australia/Melbourne",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Victoria",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Jewish Autonomous Oblast",
  },
  {
    timezone: "Asia/Vladivostok",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Khabarovsk Krai",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Primorsky Krai",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Sakha Republic",
  },
  {
    timezone: "Pacific/Guam",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Guam",
  },
  {
    timezone: "Pacific/Saipan",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Northern Mariana Islands",
  },

  {
    timezone: "na",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Lord Howe Island",
  },

  {
    timezone: "Pacific/Norfolk",
    countryCode: "AU",
    color1: "#060066",
    color2: "#FFFFFF",
    name: "Norfolk Island",
  },
  {
    timezone: "Pacific/Noumea",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "New Caledonia",
  },
  {
    timezone: "na",
    countryCode: "PG",
    color1: "#FF0200",
    color2: "#000000",
    name: "Autonomous Region of Bougainville",
  },
  {
    timezone: "Asia/Magadan",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Magadan Oblast",
  },
  {
    timezone: "Asia/Sakhalin",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Sakhalin Oblast",
  },
  {
    timezone: "Asia/Yakutsk",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Sakha Republic",
  },
  {
    timezone: "Pacific/Guadalcanal",
    countryCode: "SB",
    color1: "#1800D5",
    color2: "#006000",
    name: "Solomon Islands",
  },
  {
    timezone: "Pacific/Efate",
    countryCode: "VU",
    color1: "#CE0000",
    color2: "#3FAA3F",
    name: "Vanuatu",
  },

  {
    timezone: "Pacific/Wallis",
    countryCode: "FR",
    color1: "#00267F",
    color2: "#F21730",
    name: "Wallis and Futuna",
  },
  {
    timezone: "Pacific/Fiji",
    countryCode: "FJ",
    color1: "#68BFE5",
    color2: "#002868",
    name: "Fiji",
  },
  {
    timezone: "Pacific/Tarawa",
    countryCode: "KI",
    color1: "#E73E2C",
    color2: "#FFFFFF",
    name: "Gilbert Islands",
  },
  {
    timezone: "Pacific/Kwajalein",
    countryCode: "MH",
    color1: "#3B5AA3",
    color2: "#FFFFFF",
    name: "Marshall Islands",
  },
  {
    timezone: "Pacific/Nauru",
    countryCode: "NR",
    color1: "#012070",
    color2: "#FFB20A",
    name: "Nauru",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Chukotka Autonomous Okrug",
  },
  {
    timezone: "na",
    countryCode: "RU",
    color1: "#0138A6",
    color2: "#D52B1E",
    name: "Kamchatka Krai",
  },
  {
    timezone: "Pacific/Funafuti",
    countryCode: "TV",
    color1: "#019FCA",
    color2: "#FFF40B",
    name: "Tuvalu",
  },
  {
    timezone: "Pacific/Wake",
    countryCode: "US",
    color1: "#182E5D",
    color2: "#BD3D44",
    name: "Wake Island",
  },

  {
    timezone: "Pacific/Chatham",
    countryCode: "NZ",
    color1: "#01247D",
    color2: "#CC142B",
    name: "Chatham Islands",
  },

  {
    timezone: "Pacific/Enderbury",
    countryCode: "TV",
    color1: "#019FCA",
    color2: "#FFF40B",
    name: "Phoenix Islands",
  },
  {
    timezone: "Pacific/Fakaofo",
    countryCode: "NZ",
    color1: "#01247D",
    color2: "#CC142B",
    name: "Tokelau",
  },
  {
    timezone: "Pacific/Apia",
    countryCode: "WS",
    color1: "#CE1125",
    color2: "#002A7F",
    name: "Samoa",
  },
  {
    timezone: "Pacific/Tongatapu",
    countryCode: "TO",
    color1: "#C10000",
    color2: "#FFFFFF",
    name: "Tonga",
  },
  {
    timezone: "Pacific/Kiritimati",
    countryCode: "KI",
    color1: "#E73E2C",
    color2: "#FFFFFF",
    name: "Line Islands",
  },
];
