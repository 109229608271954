export const compareColors = (color1, color2) => {
  const white = "#FFFFFF";
  const black = "#000000";
  let outerColor;
  let innerColor;
  if (color2 === white || color1 === black) {
    outerColor = color2;
    innerColor = color1;
  } else {
    outerColor = color1;
    innerColor = color2;
  }
  return {
    outerColor,
    innerColor
  };
};
