import React, { Component } from "react";
import { getRandomPlace } from "../utils/timezones";
import "./Focs.scss";
import ReactCountryFlag from "react-country-flag";
import { compareColors } from "../utils/color";
import spacetime from "spacetime";

class Focs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focsMilliSecond: 0,
      focsSecond: 0,
      focsMintue: 0,
      focsHour: 0,
      timezone: "",
      place: "",
      countryCode: "",
      startingMinuteDegrees: "",
      endingMinuteDegrees: "",
      startingSecondDegrees: "",
      endingSecondDegrees: "",
      startingMilliSecondDegrees: "",
      endingMilliSecondDegrees: "",
      outerColor: "",
      innerColor: "",
    };
  }
  componentDidMount = async () => {
    this.updatePlace();
    this.intervalID = setInterval(() => this.tick(), 1000);
  };

  setClockHandPosition(type) {
    const { focsMilliSecond, focsSecond, focsMintue } = this.state;
    let timeParameter;
    let divideParameter;
    switch (type) {
      case "MilliSecond":
        timeParameter = focsMilliSecond;
        divideParameter = 1000;
        break;
      case "Second":
        timeParameter = focsSecond;
        divideParameter = 60;
        break;
      case "Minute":
        timeParameter = focsMintue;
        divideParameter = 60;
        break;
      default:
        break;
    }
    const rotationalFraction = timeParameter / divideParameter;
    const fullRotation = 360;
    const startingDegrees = rotationalFraction * fullRotation;
    const endingDegrees = startingDegrees + fullRotation;
    this.setState({
      [`starting${type}Degrees`]: startingDegrees,
      [`ending${type}Degrees`]: endingDegrees,
    });
  }
  setMinuteHandPostion() {
    const { focsMintue } = this.state;
    const minutesInAnHour = 60;
    const rotationalFraction = focsMintue / minutesInAnHour;
    const fullRotation = 360;
    const startingSecondDegrees = rotationalFraction * fullRotation;
    const endingSecondDegrees = startingSecondDegrees + fullRotation;
    this.setState({ startingSecondDegrees, endingSecondDegrees });
  }
  setSecondHandPostion() {
    const { focsSecond } = this.state;
    const secondsInAMinute = 60;
    const rotationalFraction = focsSecond / secondsInAMinute;
    const fullRotation = 360;
    const startingMilliSecondDegrees = rotationalFraction * fullRotation;
    const endingMilliSecondDegrees = startingMilliSecondDegrees + fullRotation;
    this.setState({ startingMilliSecondDegrees, endingMilliSecondDegrees });
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.focsHour !== this.state.focsHour) {
      this.updatePlace();
    }
  }
  tick() {
    const { timezone } = this.state;
    const newTime = spacetime.now(timezone);
    const focsMintue = newTime.minutes();
    const focsHour = newTime.hours();
    this.setState({
      focsMintue,
      focsHour,
    });
  }

  updatePlace = async () => {
    const place = getRandomPlace();
    const { timezone } = place;
    const focsTime = spacetime.now(timezone);
    const focsMilliSecond = focsTime.milliseconds();
    const focsSecond = focsTime.seconds();
    const focsMintue = focsTime.minutes();
    const focsHour = focsTime.hours();
    const { name, countryCode, color1, color2 } = place;
    this.setState({
      place: name,
      countryCode,
    });
    this.setColorScheme(color1, color2);
    await this.setState({
      focsMilliSecond,
      focsSecond,
      focsMintue,
      focsHour,
      timezone,
    });
    this.setClockHandPosition("Minute");
    this.setClockHandPosition("Second");
    this.setClockHandPosition("MilliSecond");
  };

  setColorScheme(color1, color2) {
    const { outerColor, innerColor } = compareColors(color1, color2);
    this.setState({ outerColor, innerColor });
  }

  getFocsTime() {
    const { focsMintue } = this.state;
    const minutesString = focsMintue >= 10 ? focsMintue : `0${focsMintue}`;
    const focsTime = `17:${minutesString}`;
    return focsTime;
  }

  render() {
    const {
      place,
      countryCode,
      startingMinuteDegrees,
      endingMinuteDegrees,
      startingSecondDegrees,
      endingSecondDegrees,
      startingMilliSecondDegrees,
      endingMilliSecondDegrees,
      outerColor,
      innerColor,
    } = this.state;
    const focsTime = this.getFocsTime();

    const showMinuteAnimation = startingMinuteDegrees && endingMinuteDegrees;
    const showSecondAnimation = startingSecondDegrees && endingSecondDegrees;
    const showMilliSecondAnimation =
      startingMilliSecondDegrees && endingMilliSecondDegrees;

    let minuteHandAnimationStyle = "";

    if (showMinuteAnimation) {
      minuteHandAnimationStyle = `
        @keyframes rotateMinute {
          0% {
            transform: rotate(${startingMinuteDegrees}deg);
          }
          100% {
            transform: rotate(${endingMinuteDegrees}deg);
          }
        }`;
    }

    let secondHandAnimationStyle = "";
    if (showSecondAnimation) {
      secondHandAnimationStyle = `
      @keyframes rotateSecond {
        0% {
          transform: rotate(${startingSecondDegrees}deg);
        }
        100% {
          transform: rotate(${endingSecondDegrees}deg);
        }
      }`;
    }

    let milliSecondHandAnimationStyle = "";
    if (showMilliSecondAnimation) {
      milliSecondHandAnimationStyle = `
      @keyframes rotateMilliSecond {
        0% {
          transform: rotate(${startingMilliSecondDegrees}deg);
        }
        100% {
          transform: rotate(${endingMilliSecondDegrees}deg);
        }
      }`;
    }

    return (
      <>
        <div className="container" style={{ backgroundColor: `${outerColor}` }}>
          <div
            className="box outer"
            style={{ backgroundColor: `${innerColor}` }}
          ></div>
          <div className="box inner"></div>
          {showMilliSecondAnimation && (
            <>
              <style>{milliSecondHandAnimationStyle}</style>
              <div className="box clock milliSecond">
                <div className="dot" id="dot-milliSecond"></div>
              </div>
            </>
          )}
          {showSecondAnimation && (
            <>
              <style>{secondHandAnimationStyle}</style>
              <div className="box clock second">
                <div className="dot" id="dot-second"></div>
              </div>
            </>
          )}

          {showMinuteAnimation && (
            <>
              <style>{minuteHandAnimationStyle}</style>
              <div className="box clock minute">
                <div className="dot" id="dot-minute"></div>
              </div>
            </>
          )}

          <div className="box clock hour">
            <div className="dot" id="dot-hour"></div>
          </div>

          <div className="text-container box">
            <div className="face">
              {countryCode && (
                <div className="flag-container">
                  <ReactCountryFlag
                    style={{
                      fontSize: "3.5em",
                      width: "auto",
                    }}
                    countryCode={countryCode}
                    svg
                  />
                </div>
              )}
              <p id="time">{focsTime}</p>
              <p id="place">{place}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Focs;
