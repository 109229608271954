import spacetime from "spacetime";
import { timezones } from "../data/timezones";

export const getRandomPlace = () => {
  const focsTimezones = spacetime.whereIts("5pm");
  const matchingTimezones = timezones.filter((timezone) =>
    focsTimezones.includes(timezone.timezone.toLowerCase())
  );
  const numberOfPossibleOptions = matchingTimezones.length;
  const randomIndex = getRandomIndex(numberOfPossibleOptions);
  const randomPlace = matchingTimezones[randomIndex];
  return randomPlace;
};

const getRandomIndex = (numberOfPossibleOptions) => {
  const randomIndex = Math.floor(Math.random() * numberOfPossibleOptions);
  return randomIndex;
};
